/* unvisited link */
.link-evt:link {
  color: white;
}

/* visited link */
.link-evt:visited {
  color: white;
}

/* mouse over link */
.link-evt:hover {
  color: white;
}

/* selected link */
.link-evt:active {
  color: white;
}

.custom-col {
  padding: 3% !important;
}

.custom-card {
  border: 1px solid #003188 !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  border-radius: 25px #003188 !important;
  background-color: rgb(230, 230, 230) !important;
}

.custom-card .card-body {
  flex: 1 !important;
}

.custom-button {
  background-color: transparent !important;
  border: 1px solid #003188 !important;
  color: #003188 !important;
}

.custom-button:hover {
  background-color: #002367 !important; /* Darker shade for hover */
  border: 1px solid #002367 !important;
  color:white !important;
}

.custom-breadcrumb {
}

.breadcrumb a,
.breadcrumb-link:hover {
  color: #003188 !important; /* Set color for normal and hover state */
}

.breadcrumb-active {
  color: #000000; /* Set color for the active state */
}
