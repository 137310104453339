.row-agenda{
    display: grid;
}
.col-agenda{
    grid-auto-rows: 1fr;
    padding:2%;
}
.card-agenda {
    border-left: 3px solid #1e3d52 !important;
    border-bottom: 3px solid #1e3d52 !important;
    height:100% !important;
}
