#div-boton-inscribirse{
    /*style={{ width: '200px', position: 'fixed', right: '0%', bottom: '5%', zIndex: 2, textAlign: 'right' }}*/
    width: 200px !important;
    position: fixed !important;
    right: 0% !important;
    bottom: 18% !important;
    z-index: 2 !important;
}
@media screen and (max-width: 500px) {
    #div-boton-inscribirse{
        /*style={{ width: '200px', position: 'fixed', right: '0%', bottom: '5%', zIndex: 2, textAlign: 'right' }}*/
        width: 200px !important;
        position: fixed !important;
        right: 0% !important;
        bottom: 18% !important;
        z-index: 2 !important;
    }
}