.row-tipo{
    display: flex;
}
.col-tipo{
    grid-auto-rows: 1fr;
    padding:2%;
}
.card-tipo {
    height:100% !important;
    width: 100% !important;
    display: grid;
    align-items: center;
}
