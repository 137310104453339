@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap');

.font-monse {}

.btn-acerca:hover {

    background-color: #1e3d52 !important;
    border-color: #1e3d52 !important;
    color: white !important;
}

.btn-acerca:focus {

    background-color: #1e3d52 !important;
    border-color: #1e3d52 !important;
    color: white !important;
    box-shadow: 0 0 0 0.25rem rgba(var(30, 61, 82), .5) !important;
}

.btn-acerca {

    color: #1e3d52 !important;
    border-color: #1e3d52 !important;
}

.more {
    background-color: transparent;
    background-image: linear-gradient(180deg, #003188 0%, #011F54 100%);
}


.accordion-button::after {
    width: 0px !important;
}