.button-inscribete {
    background-color: #003188 !important;
    border-color: #003188 !important;
}

.button-inscribete:hover {
    background-color: white !important;
    color: black !important
}

.footer-button:hover {
    background-color: #003188 !important;

}

#mas-info{
    border: 1px solid transparent !important;
    border-radius: 100%;
    
    width: 100px;
    height: 100px;
}
#div-boton{
    /*style={{ width: '200px', position: 'fixed', right: '0%', bottom: '5%', zIndex: 2, textAlign: 'right' }}*/
    width: 200px;
    position: fixed;
    right: -3%;
    bottom: 5%;
    z-index: 2;
}
@media screen and (max-width: 500px) {
    #div-boton{
        /*style={{ width: '200px', position: 'fixed', right: '0%', bottom: '5%', zIndex: 2, textAlign: 'right' }}*/
        width: 200px;
        position: fixed;
        right: -20%;
        bottom: 3%;
        z-index: 2;
    }
}