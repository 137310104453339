.card{
    border: 1px solid transparent;
}
.row-events{
    display: flex;
}
.col-events{
    grid-auto-rows: 1fr;
    padding:2%;
}
.card-events {
    height:100% !important;
    width: 100% !important;
    display: grid;
    align-items: center;
}
