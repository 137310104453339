#modal-mas .modal-content{
    color:white !important;
    background-color: transparent !important;
    width: 250px !important;
    left: 50vw;
    top: 55vh;
    border:1px solid transparent !important;
    ;
}
#modal-mas .modal-header{
    border-bottom: 1px solid transparent !important;
}


@media screen and (max-width: 500px) {
    #modal-mas .modal-content{
        color:white !important;
        background-color: transparent !important;
        width: 250px !important;
        left: 40vw;
        top: 50vh;
        border:1px solid transparent !important;
    }
}
