@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.row-contacto div a {
    color: black;
}

.row-contacto div {
    padding: 16px;
}

.row-contacto {
    cursor: pointer;
    transition: all 0.5s linear;
}

.row-contacto:hover {
    background-color: #1e3d52;
    border-radius: 32px;
}

.row-contacto:hover div {
    color: white;
}

.row-contacto:hover div svg {
    width: 60px;
    height: 60px;
}

.container-fluid {
    font-family: 'Helvetica', 'Arial', sans-serif;

}

.card {
    transition: all 0.5s linear;

}

.card:hover {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

p {
    font-family: 'Helvetica', 'Arial', sans-serif;

}

h3 {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

.button-card:hover {

    background-color: #1e3d52 !important;
    border-color: #1e3d52 !important;
    color: white !important;
}

.button-card:focus {

    background-color: #1e3d52 !important;
    border-color: #1e3d52 !important;
    color: white !important;
    box-shadow: 0 0 0 0.25rem rgba(var(30, 61, 82), .5) !important;
}



.button-card {

    color: #1e3d52 !important;
    border-color: #1e3d52 !important;
}

